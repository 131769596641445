<template>
<router-view/>
</template>


<style lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* hide scrollbar on Chrome, Edge */
body::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
}

/* hide scrollbar firefox */
html {
    scrollbar-width: none;
}

body,input,textarea {
    font-family: 'Helvetica-Regular',sans-serif;
}

img {
    vertical-align: middle;
}

// (multiselect, select) styles
.select {
    margin: 7px 0 15px;
    border: none;

    > .vs__dropdown-toggle {
        border: none!important;
        font-size: 14px;

        .vs__selected-options {
            > input::placeholder {
                font-size: 16px;
                color: #6E6E6E;
            }
        }
    }

    > ul.vs__dropdown-menu {
        border: none;
    }
}

.Vue-Toastification__container {
    width: 380px !important;
}

.Vue-Toastification__toast {
    border-radius: 8px !important;
}

@media (max-width: 600px) {
    .Vue-Toastification__container {
        width: 100vw !important;
        padding: 0 15px !important;
    }

    .Vue-Toastification__toast {
        border-radius: 8px !important;
    }
}

// animations
@keyframes SlideUp {
    0% {
        transform: translateY(3.25rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes SlideDown {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(3.25rem);
        opacity: 0;
    }
}
</style>
