export const modal = {
    namespaced: true,
    state: {
        modalComponent: {
            // type: string, withContainer: Bool, fullscreen: bool, componentData
        }
    },
    mutations: {
        setModalComponent(state, component) {
            state.modalComponent = component
        }
    },
    getters: {
        getModalComponent(state) {
            return state.modalComponent
        },
        modalValue(state) {
            return state.modalComponent.modalValue
        }
    }
}
