import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/style.css';

// notification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    position: "bottom-right",
    timeout: 4000,
    newestOnTop: true,
    hideProgressBar: true,
    maxToasts: 5
}

// interceptors
import setupInterceptors from './services/setupInterceptors';

setupInterceptors(store);

// emitter
import mitt from 'mitt';

const emitter = mitt();


const app = createApp(App)

app.config.globalProperties.emitter = emitter;

app.use(store).use(router).use(Toast, options).mount('#app')
