<template lang="pug">
.user-dropdown-container(v-if="profileData" @click="showDropDown=!showDropDown" ref="userDropdown")
	img(:src="userProfileImage")
	h3 {{ profileData.first_name }} {{ profileData.last_name }}
	.user-dropdown(:class="{'opened-dropdown': showDropDown, 'closed-dropdown': showDropDown !== null && !showDropDown}" @click.stop)
		.user-children-container
			.user-child(v-for="child in userChildren" :key="child.id" @click="setProfile(child.id, child.biography_id)")
				img(:src="child.profile_image ? child.profile_image : require('../assets/images/defaultImage.png')")
				h3 {{ child.first_name }} {{ child.last_name }}
		p(@click="openModal(1)") პროფილის დამატება
		router-link(:to="{path: '/edit-profile'}" @click="showDropDown = false") პროფილის რედაქტირება
		router-link(:to="{path: '/my-posts'}" @click="showDropDown = false") ჩემი პოსტები
		router-link(:to="{path: '/user-verification'}" @click="showDropDown = false") დამსაქმებლის ვერიფიკაცია
		p(@click="logOut") გასვლა
</template>

<script>
import {mapGetters} from "vuex";

import AddProfile from "../components/modal-contents/AddProfile";
import tokenService from "../services/token.service";
import defaultImage from "../assets/images/defaultImage.png"

// composition Api
import {ref} from "vue";
import {onClickOutside} from "@vueuse/core";

export default {
	name: "UserDropdown",
	components: {AddProfile},
	props: {
		data: {
			type: Object,
			default: () => {
				return {
					image: defaultImage,
					name: "Name",
					lastname: "Lastname"
				}
			}
		}
	},
	setup() {
		const userDropdown = ref(null)
		const showDropDown = ref(null)

		onClickOutside(userDropdown, () => {
			if (showDropDown.value === true) showDropDown.value = false
		})

		return {userDropdown, showDropDown}
	},
	data() {
		return {
			isActiveProfileChild: false
		}
	},
	computed: {
		...mapGetters({
			profileData: "user/profileData",
			mainUserData: "user/mainUserData",
			userChildren: "user/userChildren",
			userProfileImage: "user/userProfileImage",
			mainProfileBiographyId: "user/mainProfileBiographyId"
		})
	},
	mounted() {
		this.$store.commit('user/setActiveProfile', tokenService.getItem("activeProfile") ?
			Number(tokenService.getItem("activeProfile")) : null)
		this.$store.commit('user/setActiveChildId', tokenService.getItem("activeChildId") ?
			Number(tokenService.getItem("activeChildId")) : null)
	},
	methods: {
		logOut() {
			this.$store.dispatch("user/logout", this.data).then(() => {
				this.showDropDown = false
			})
		},
		openModal() {
			this.showDropDown = false
			if (!this.isActiveProfileChild) {
				this.$store.commit("modal/setModalComponent", {
					type: "AddProfile",
					withContainer: false,
					fullscreen: true,
					componentData: {
						data: [],
						modalContent: true
					}
				})
			}
		},
		setProfile(profileId, biographyId) {
			this.showDropDown = false

			// add isChild to browser storage for navigation guard
			if (this.mainUserData.children.find(el => el.id === profileId)) {
				this.isActiveProfileChild = true
				tokenService.setItem('isChild', true)
			} else {
				this.isActiveProfileChild = false
				tokenService.removeItem('isChild')
			}

			if (biographyId !== null) {
				// if user have biography
				tokenService.removeItem("activeChildId")
				this.$store.commit('user/setActiveChildId', null)

				tokenService.setItem("activeProfile", biographyId)
				this.$store.commit('user/setActiveProfile', biographyId)

				// fetch data for new profile
				this.$store.dispatch('user/fetchActiveOffers') // active offers
				this.$store.dispatch('user/fetchUserNotifications') // notifications
				if (this.$route.name === 'edit-profile') {
					this.$store.dispatch('user/fetchUserBiography', biographyId)
				}
			} else {
				if (this.mainUserData.children.find(el => el.id === profileId)) {
					// child without biography
					// remove active profile
					tokenService.removeItem("activeProfile")
					this.$store.commit('user/setActiveProfile', null)

					// add child id
					tokenService.setItem("activeChildId", profileId)
					this.$store.commit('user/setActiveChildId', profileId)
					// redirect to edit profile, to add biography
					this.$router.push({path: '/edit-profile'})
				} else {
					// main user without biography
					tokenService.removeItem("activeChildId")
					tokenService.removeItem("activeProfile")
					this.$store.commit('user/setActiveChildId', null)
					this.$store.commit('user/setActiveProfile', null)

					// redirect to edit profile, to add biography
					this.$router.push({path: '/edit-profile'})
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
.user-dropdown-container {
	min-width: 170px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 10px;
	position: relative;
	cursor: pointer;
	user-select: none;

	> h3 {
		font-size: 18px;
		font-weight: 700;
	}

	> img {
		border-radius: 50%;
		width: 38px;
		height: 38px;
		object-fit: cover;
	}

	/* user dropdown */

	.user-dropdown.opened-dropdown {
		animation: .4s cubic-bezier(.58, .3, .005, 1) SlideUp;
		z-index: 10000;
		transform: translateY(0);
		opacity: 1;
		pointer-events: auto;
	}

	.user-dropdown.closed-dropdown {
		animation: .4s cubic-bezier(.58, .3, .005, 1) SlideDown;
		transform: translateY(3.25rem);
		pointer-events: none;
		opacity: 0;
	}

	.user-dropdown {
		position: absolute;
		top: 100%;
		right: 0;
		width: 230px;
		margin-top: 20px;
		background: #FFFFFF 0 0 no-repeat padding-box;
		box-shadow: 0 3px 20px #00000029;
		border-radius: 5px;
		padding: 12px;
		opacity: 0;
		pointer-events: none;

		p {
			font: normal normal normal 14px/17px Helvetica;
			margin-bottom: 8px;
			cursor: pointer;
			user-select: none;
		}

		p:last-child {
			margin: 0;
		}

		a {
			display: block;
			user-select: none;
			color: #000000;
			margin-bottom: 10px;
			text-decoration: none;
			font: normal normal normal 14px/17px Helvetica;
		}

		.user-children-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-bottom: 10px;

			.user-child {
				display: flex;
				align-items: center;
				gap: 20px;

				img {
					width: 31px;
					height: 31px;
					border-radius: 50%;
				}

				h3 {
					font-size: 14px;
				}
			}
		}
	}
}
</style>
