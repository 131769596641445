<template lang="pug">
main-header(v-if="!$route.meta.headerHide")
.content
	router-view
DefaultModal(v-if="!!modalContent.type" )
	component(:is="modalContent.type" :data="modalContent.componentData.data")
</template>

<script>
import tokenService from "../services/token.service";
import {mapGetters} from "vuex";

import Header from "../components/layout/Header";
import DefaultModal from "../components/layout/DefaultModal";
import DeleteFilter from "../components/modal-contents/DeleteFilter";
import PublishPost from "../components/modal-contents/PublishPost.vue";
import AddProfile from "../components/modal-contents/AddProfile.vue";
import PostOffer from "../components/modal-contents/PostOffer.vue";

export default {
	name: "Website",
	components: {
		PostOffer,
		AddProfile,
		PublishPost,
		DeleteFilter,
		DefaultModal,
		'main-header': Header,
	},
	mounted() {
		if (this.mainUserData.biography_id && !Number(tokenService.getItem("activeProfile")) &&
			!Number(tokenService.getItem("activeChildId"))) {
			tokenService.setItem('activeProfile', this.mainUserData.biography_id)
		}
	},
	computed: {
		...mapGetters({
			modalContent: "modal/getModalComponent",
			mainUserData: "user/mainUserData"
		})
	}
}
</script>

<style lang="scss">
.content {
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
	padding: 0 30px;
}

button {
	cursor: pointer;
}
</style>
