import {ajax, apiUrls} from "@/api/urls";

export const posts = {
    namespaced: true,
    state: {
        selectedFilters: [],
        filtersBreadcrumbs: [],
        tags: [],
        eye_color: [],
        hair_color: [],
        skin_color: [],
        gender: [
            {
                title: "მამრობითი",
                id: 0
            },
            {
                title: "მდედრობითი",
                id: 1
            },
            {
                title: "სხვა",
                id: 2
            }
        ],
        date: [null, null],
        age: [0, 100],
        height: [0, 200],
        weight: [0, 200],
        characteristics: [],
        posts: {},
        singlePost: {},
        myPosts: {},
        interestedModels: []
    },
    actions: {
        fetchFiltersPromise({dispatch}) {
            // for edit-profile
            dispatch("fetchTags")
            return Promise.all([
                dispatch("fetchColors", {param: {is_eye_color: true}, name: 'eye_color'}),
                dispatch("fetchColors", {param: {is_hair_color: true}, name: 'hair_color'}),
                dispatch("fetchColors", {param: {is_skin_color: true}, name: 'skin_color'}),
                dispatch("fetchCharacteristics")
            ])
        },
        fetchFilters({dispatch}) {
            dispatch("fetchColors", {param: {is_eye_color: true}, name: 'eye_color'})
            dispatch("fetchColors", {param: {is_hair_color: true}, name: 'hair_color'})
            dispatch("fetchColors", {param: {is_skin_color: true}, name: 'skin_color'})
            dispatch("fetchTags")
            dispatch("fetchCharacteristics")
        },
        fetchColors({commit}, color) {
            return ajax.get(apiUrls.colorsList, {
                params: color.param
            }).then((res) => {
                commit("setFilters", {data: res.data, name: color.name})
            }).catch(() => {
            })
        },
        fetchTags({commit}) {
            return ajax.get(apiUrls.tags).then((res) => {
                commit("setFilters", {data: res.data, name: "tags"})
            }).catch(() => {
            })
        },
        fetchCharacteristics({commit}) {
            return ajax.get(apiUrls.characteristics).then((res) => {
                commit("setFilters", {data: res.data, name: "characteristics"})
            }).catch(() => {
            })
        },
        fetchPosts({commit}, params = {}) {
            ajax.get(apiUrls.postsList, {
                params: params
            }).then((res) => {
                commit("setPosts", res.data)
            })
        },
        loadMorePosts({state, commit}) {
            const nextUrl = state.posts.next
            ajax.get(nextUrl).then((res) => {
                commit("addPosts", res.data)
            })
        },
        fetchSinglePost({commit}, postId) {
            return ajax.get(apiUrls.singlePost(postId)).then((res) => {
                commit("setSinglePost", res.data)
                return res
            }).catch(() => {
            })
        },
        fetchMyPosts({commit}) {
            ajax.get(apiUrls.myPosts).then(res => {
                commit('setMyPosts', res.data)
            })
        },
        addPost({commit}, postData) {
            return ajax.post(apiUrls.postsList, postData).then(res => {
                return res
            }).catch(() => {
            })
        },
        updatePost({commit}, {postId, postData}) {
            return ajax.put(apiUrls.singlePost(postId), postData).then(res => {
                return res
            }).catch(() => {
            })
        },
        partialPostUpdate({}, {postId, data}) {
            return ajax.patch(apiUrls.singlePost(postId), data).then(res => {
                return res
            }).catch(() => {
            })
        },
        publishPost({commit}, postId) {
            return ajax.post(apiUrls.publishPost(postId)).then(res => {
                return res
            }).catch(() => {
            })
        },
        fetchInterestedModels({commit}, postId) {
            return ajax.get(apiUrls.interestedModels(postId)).then(res => {
                commit('setInterestedModels', res.data)
            }).catch(err => {
                return err
            })
        }
    },
    mutations: {
        setFilters(state, param) {
            state[param.name] = param.data
        },
        addSelectedFilter(state, data) {
            state.selectedFilters.push(data)
        },
        setSelectedFilter(state, data) {
            state.selectedFilters = data
        },
        removeSelectedFilter(state, index) {
            state.selectedFilters.splice(index, 1)
        },
        addFiltersBreadcrumb(state, data) {
            state.filtersBreadcrumbs.push(data)
        },
        setFiltersBreadcrumb(state, data) {
            state.filtersBreadcrumbs = data
        },
        setPosts(state, data) {
            state.posts = data
        },
        addPosts(state, {count, next, previous, results}) {
            results.forEach(el => {
                state.posts.results.push(el)
            })
            state.posts = {...state.posts, count, next, previous}
        },
        setSinglePost(state, data) {
            state.singlePost = data
        },
        setMyPosts(state, data) {
            state.myPosts = data
        },
        setInterestedModels(state, data) {
            state.interestedModels = data
        }
    },
    getters: {
        tags(state) {
            return state.tags
        },
        eye_color(state) {
            return state.eye_color
        },
        hair_color(state) {
            return state.hair_color
        },
        skin_color(state) {
            return state.skin_color
        },
        gender(state) {
            return state.gender
        },
        date(state) {
            return state.date
        },
        age(state) {
            return state.age
        },
        height(state) {
            return state.height
        },
        weight(state) {
            return state.weight
        },
        characteristics(state) {
            return state.characteristics
        },
        selectedFilters(state) {
            return state.selectedFilters
        },
        filtersBreadcrumbs(state) {
            Object.keys(state.filtersBreadcrumbs).forEach(key => {
                if (!state.filtersBreadcrumbs[key] || Object.keys(state.filtersBreadcrumbs[key]).length === 0)
                    delete state.filtersBreadcrumbs[key]
            })
            return state.filtersBreadcrumbs
        },
        posts(state) {
            return state.posts
        },
        post(state) {
            return state.singlePost
        },
        postVacancies(state) {
            return state.singlePost.vacancies
        },
        myPosts(state) {
            return state.myPosts
        },
        getInterestedModels(state) {
            return state.interestedModels
        }
    }
};
