import {createStore} from 'vuex'
import {user} from './user';
import {posts} from './posts';
import {modal} from './modal';


export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        posts,
        modal
    }
})
