<template lang="pug">
.publish-post
    p პოსტის გამოქვეყნების შემდეგ ვეღარ შეძლებთ პოსტის ფილტრების რედაქტირებას
    .publish-post-buttons
        button(@click="setModalValue") ᲒᲐᲛᲝᲥᲕᲔᲧᲜᲔᲑᲐ
        button(@click="closeModal") ᲒᲐᲣᲥᲛᲔᲑᲐ
</template>

<script>
export default {
    name: "PublishPost",
    methods: {
        setModalValue() {
            this.$store.commit("modal/setModalComponent", { type: "", modalValue: { success: true, name: 'PublishPost' } })
        },
        closeModal() {
            this.$store.commit("modal/setModalComponent", { type: "" })
        }
    }
}
</script>

<style lang="scss" scoped>
.publish-post {
    width: 439px;
    height: 181px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 20px #00000029;
    border-radius: 5px;
    padding: 20px;

    p {
        font-size: 16px;
        color: #6E6E6E;
        user-select: none;
    }

    .publish-post-buttons {
        display: flex;
        align-items: center;
        gap: 20px;

        > button {
            border: none;
            font: normal normal bold 14px/16px Helvetica;
            letter-spacing: 0;
            color: #FFFFFF;
            background: #92EB8E 0 0 no-repeat padding-box;
            border-radius: 10px;
            padding: 7px 11px;
        }

        > button:nth-child(2) {
            background: #F86363 0 0 no-repeat padding-box;
        }
    }
}
</style>
