import {getCookie, setCookie, removeCookie} from "../middleware/cookies";

class TokenService {
    getLocalRefreshToken() {
        const user = this.getUser();
        return user?.refresh;
    }

    getLocalAccessToken() {
        const user = this.getUser();
        return user?.access;
    }

    getUser() {
        const user = getCookie("user");
        try {
            return JSON.parse(user);
        } catch (e) {
            removeCookie("user")
            return {};
        }
    }

    getUserStatus() {
        return JSON.parse(localStorage.getItem("status"));
    }

    setUser(user) {
        setCookie("user", JSON.stringify(user), 60 * 24)
    }

    setUserStatus(status) {
        localStorage.setItem("status", status)
    }

    removeUser() {
        removeCookie("user");
    }

    removeUserStatus() {
        localStorage.removeItem("status")
    }

    getXCSRFToken() {
        return getCookie('csrftoken')
    }

    setItem(key, value) {
        localStorage.setItem(key, value)
    }

    getItem(key) {
        return localStorage.getItem(key)
    }

    removeItem(key) {
        localStorage.removeItem(key)
    }
}

export default new TokenService();
