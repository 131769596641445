import axios from "axios";

export const ajax = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ka"
    }
})

export const apiUrls = {
    // User
    userRegister: '/api/user/register/',
    userActive: (uid, token) => `/api/user/activate-user/${uid}/${token}/`,
    userActiveCodeSend: (uid, token) => `/api/user/activate-user/${uid}/${token}/send-sms/`,
    tokenAuth: '/api/user/token-auth/',
    tokensRefresh: '/api/user/token-refresh/',
    userLogout: '/api/user/logout/',
    userData: '/api/user/userdata/',
    physicalEmployer: '/api/user/physical-employer/',
    companyEmployer: '/api/user/legal-employer/',
    addProfile: "/api/user/create-child/",
    userBiographies: "/api/user/biographies/",
    singleBiography: biographyId => `/api/user/biographies/${biographyId}/`,
    recoverySendSms: phoneNumber => `/api/user/recovery/${phoneNumber}/send-sms/`,
    recoverySendCode: phoneNumber => `/api/user/recovery/${phoneNumber}/`,
    changePassword: (phoneNumber, token) => `/api/user/recovery/${phoneNumber}/${token}/`,
    userImages: '/api/user/images/',
    deleteImage: (imageId) => `/api/user/images/${imageId}/`,
    userNotifications: '/api/career/notifications/',

    //  Posts - Filters
    postsList: '/api/career/posts/',
    singlePost: postId => `/api/career/posts/${postId}/`,
    colorsList: '/api/management/colors/',
    tags: '/api/management/tags/',
    characteristics: '/api/management/characteristics/',
    publishPost: postId => `/api/career/posts/${postId}/publish/`,
    myPosts: '/api/career/posts/my-posts/',
    interestedModels: postId => `/api/career/posts/${postId}/interested_models/`,

    // Vacancies
    addVacancy: '/api/career/vacancies/',
    deleteVacancy: vacancyId => `/api/career/vacancies/${vacancyId}/`,

    // Offer
    offers: '/api/career/offers/',
    updateOffer: offerId => `/api/career/offers/${offerId}/`
}
