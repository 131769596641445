<template lang="pug">
.post-offer
    .post-offer-user
        img(:src="postOfferAuthorImage")
        p {{ postAuthor }}
    .post-offer-information
        p(v-html="data.post.description")
        .post-offer-date
            img(src="@/assets/images/Icon-feather-calendar.svg")
            p {{ postDuration }}
        .post-offer-information-bottom
            h3 {{ postSalary }}
            .post-offer-information-bottom-right
                p {{ data.post.interests }}
                img(src="@/assets/images/Icon-ionic-ios-heart-empty-black.svg")
        div
            .post-offer-information-divider
            .open-post-offer-filters(@click="filtersShow=!filtersShow")
                .open-button
                    h3 ფილტრები
                    img(src="@/assets/images/Icon-ionic-ios-arrow-up.svg" :class="{rotate: filtersShow}")
                p კატეგორია
            .post-offer-filters(:class="{open: filtersShow}")
                UserFilter(:data="data.vacancy")
        .post-offer-accept-reject
            button ᲣᲐᲠᲘ
            button ᲗᲐᲜᲮᲛᲝᲑᲐ
</template>

<script>
import UserFilter from "@/components/layout/UserFilter";
import dateFormat from "@/middleware/dateFormat";
import defaultImage from "@/assets/images/defaultImage.png";
export default {
    name: "PostOffer",
    components: {UserFilter},
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            filtersShow: false,
        }
    },
    computed: {
        postAuthor () {
            return this.data.biography.user.first_name + " " + this.data.biography.user.last_name
        },
        postOfferAuthorImage() {
            return this.data.biography.user.profile_image !== null ? this.data.biography.user.profile_image : defaultImage
        },
        postDuration () {
            return dateFormat(this.data.post.start_date).dayNumber + " " + dateFormat(this.data.post.start_date).month + " - " + dateFormat(this.data.post.end_date).dayNumber + " " + dateFormat(this.data.post.end_date).month
        },
        postSalary () {
            return this.data.post.is_salary ? "$ ანაზღაურებადი" : " "
        }
    }
}
</script>

<style lang="scss" scoped>
.post-offer {
    margin: 20px 0;
    overflow: hidden;
    max-width: 644px;

    .post-offer-user {
        margin-bottom: 10px;
        display: flex;
        gap: 5px;
        align-items: center;

        img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            object-fit: cover;
        }

        p {
            font-size: 14px;
            color: #212121;
        }
    }

    .post-offer-information {
        padding: 20px;
        box-shadow: 0 3px 20px #00000029;
        border-radius: 5px;
        background-color: #FFFFFF;

        > p {
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0;
            color: #6E6E6E;
            text-align: left;
            margin-bottom: 10px;
        }

        .post-offer-date {
            display: flex;
            justify-content: right;
            gap: 6px;


            span {
                color: #4354B4;
            }

            p {
                font-size: 14px;
                color: #4354B4;
            }
        }

        .post-offer-information-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            h3 {
                font: normal normal bold 14px/16px 'Helvetica-Bold';
                color: #4354B4;
            }

            .post-offer-information-bottom-right {
                display: flex;
                align-items: center;
                gap: 5px;

                p {
                    font-size: 16px;
                    color: #6E6E6E;
                }

                img {
                    width: 25px;
                    height: 24px;
                }
            }
        }

        .post-offer-information-divider {
            width: 100%;
            height: 1px;
            background-color: #DDDDDD;
            margin: 10px 0;
        }

        .open-post-offer-filters {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > .open-button {
                display: flex;
                align-items: center;
                gap: 5px;

                > h3 {
                    font: normal normal bold 13px/15px 'Helvetica-Bold';
                    letter-spacing: 0;
                    color: #212121;
                    cursor: pointer;
                }

                > img {
                    transition: .3s;
                }

                > img.rotate {
                    transform: rotate(180deg);
                }
            }

            > p {
                font: normal normal 300 13px/16px 'Helvetica-Light';
                letter-spacing: 0;
                color: #212121;
            }
        }

        .post-offer-filters {
            width: 100%;
            max-height: 0;
            transition: .4s;
            opacity: 0;
            visibility: hidden;
        }

        .post-offer-filters.open {
            max-height: 100vh;
            opacity: 1;
            visibility: visible;
            margin-top: 15px;
        }

        .post-offer-accept-reject {
            margin-top: 12px;
            padding-top: 25px;
            border-top: 1px solid #DDDDDD;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            > button {
                border: none;
                background: #F86363 0 0 no-repeat padding-box;
                border-radius: 5px;
                font: normal normal bold 12px/14px 'Helvetica-Bold';
                letter-spacing: 0;
                color: #FFFFFF;
                padding: 4px 10px;
            }

            > button:last-child {
                background: #92EB8E 0 0 no-repeat padding-box;
            }
        }
    }
}
</style>
