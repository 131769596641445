import {ajax, apiUrls} from "../api/urls";
import tokenService from "../services/token.service";
import router from "../router";
import defaultImage from "../assets/images/defaultImage.png"

export const user = {
    namespaced: true,
    state: {
        userData: {},
        phoneLastNumbers: null,
        activeOffers: [],
        biographies: {},
        activeProfile: null,
        activeChildId: null,
        userNotifications: {},
        userBiography: {
            images: []
        }
    },
    actions: {
        userRegister({commit}, data) {
            return ajax.post(apiUrls.userRegister, data).then((res) => {
                return Promise.resolve(res.data);
            }).catch(err => {
                return Promise.reject(err.response.data);
            })
        },
        userLastNumbers({commit}, data) {
            return ajax.get(apiUrls.userActive(data.uid, data.token)).then((res) => {
                commit('setLastNumbers', res.data.phone)
                return res.data
            })
        },
        userActiveCodeSend({commit}, data) {
            return ajax.post(apiUrls.userActiveCodeSend(data.uid, data.token)).then((res) => {
                return res.data
            })
        },
        userActiveCodeValidate({commit}, data) {
            return ajax.post(apiUrls.userActive(data.uid, data.token), data.params).then((res) => {
                return Promise.resolve(res.data);
            }).catch(err => {
                return Promise.reject(err.response.data);
            })
        },
        login({commit, dispatch}, data) {
            return ajax.post(apiUrls.tokenAuth, data).then((res) => {
                if (res.data.access) {
                    tokenService.setUser(res.data);
                }
                return Promise.resolve(res.data);
            }).catch(err => {
                if (err.response.status === 302) {
                    router.push({
                        name: 'Verification',
                        params: {uid: err.response.data.uid, token: err.response.data.token}
                    })
                }
                return Promise.reject(err.response.data);
            })
        },
        userData({commit}) {
            if (tokenService.getLocalAccessToken()) {
                return ajax.get(apiUrls.userData).then(response => {
                    tokenService.setUserStatus(response.data.status)
                    commit('setUserData', response.data);
                    return response
                })
            }
        },
        logout({commit}) {
            const token = tokenService.getLocalRefreshToken()
            ajax.post(apiUrls.userLogout, {data: {'refresh': token}}).then((res) => {
                if (res) {
                    tokenService.removeUser();
                    tokenService.removeUserStatus();
                    tokenService.removeItem('activeProfile')
                    router.push({name: 'Welcome'})
                    commit('userLogout');
                }
            })
        },
        fetchActiveOffers({commit}) {
            ajax.get(apiUrls.offers).then(res => {
                commit('setActiveOffers', res.data)
            }).catch(() => {
            })
        },
        fetchUserBiography({commit}, biographyId) {
            ajax.get(apiUrls.singleBiography(biographyId)).then((res) => {
                commit('setUserBiography', res.data)
            }).catch(() => {
            })
        },
        fetchBiographies({commit}, params = {}) {
            ajax.get(apiUrls.userBiographies, {
                params: params
            }).then((res) => {
                commit("setBiographies", res.data)
            }).catch(() => {
            })
        },
        loadMoreBiographies({state, commit}) {
            const nextUrl = state.biographies.next
            ajax.get(nextUrl).then((res) => {
                commit('addBiographies', res.data)
            }).catch(() => {
            })
        },
        sendImages({dispatch}, formData) {
            ajax.post(apiUrls.userImages, formData).then(res => {
                dispatch('fetchUserBiography', res.data.biography)
                dispatch('userData')
            }).catch(() => {
            })
        },
        deleteImage({state, dispatch}, imageId) {
            ajax.delete(apiUrls.deleteImage(imageId)).then(() => {
                dispatch('fetchUserBiography', state.userBiography.id)
                dispatch('userData')
            }).catch(() => {
            })
        },
        updateOffer({state, dispatch}, {status, offerId}) {
            ajax.patch(apiUrls.updateOffer(offerId), {status: status}).then(() => {
                dispatch('fetchActiveOffers')
            }).catch(() => {
            })
        },
        fetchUserNotifications({commit}) {
            return ajax.get(apiUrls.userNotifications).then(res => {
                commit('setUserNotifications', res.data)
            }).catch(() => {
            })
        }
    },
    mutations: {
        setUserData(state, data) {
            state.userData = data
        },
        userLogout(state) {
            state.userData = {}
        },
        setLastNumbers(state, data) {
            state.phoneLastNumbers = data
        },
        setActiveOffers(state, data) {
            state.activeOffers = data
        },
        setUserBiography(state, data) {
            state.userBiography = data
        },
        setBiographies(state, data) {
            state.biographies = data
        },
        addBiographies(state, {count, next, previous, results}) {
            results.forEach(el => {
                state.biographies.results.push(el)
            })
            state.biographies = {...state.biographies, count, next, previous}
        },
        setActiveProfile(state, data) {
            state.activeProfile = data
        },
        setActiveChildId(state, data) {
            state.activeChildId = data
        },
        setUserNotifications(state, data) {
            state.userNotifications = data
        }
    },
    getters: {
        isAuthorized(state) {
            return !!Object.keys(state.userData).length
        },
        phoneLastNumbers(state) {
            return state.phoneLastNumbers
        },
        activeOffers(state) {
            return state.activeOffers
        },
        mainUserData(state) {
            return state.userData
        },
        profileData(state) {
            if (state.activeChildId !== null) return state.userData.children.find(el => el.id === state.activeChildId)
            if (state.activeProfile !== null && state.activeProfile !== state.userData.biography_id) {
                return state.userData.children.find(el => el.biography_id === state.activeProfile)
            } else return state.userData
        },
        mainProfileId(state) {
            return state.userData.id
        },
        mainProfileBiographyId(state) {
            return state.userData.biography_id
        },
        userChildren(state) {
            if (state.activeChildId !== null || state.activeProfile !== null && state.activeProfile !== state.userData.biography_id) {
                let modifiedUserChildren = state.userData.children.filter(el => {
                    return state.activeChildId !== null ? el.id !== state.activeChildId : el.biography_id !== state.activeProfile
                })
                modifiedUserChildren.unshift({
                    biography_id: state.userData.biography_id,
                    first_name: state.userData.first_name,
                    last_name: state.userData.last_name,
                    id: state.userData.id,
                    profile_image: state.userData.profile_image
                })
                return modifiedUserChildren
            } else return state.userData.children
        },
        userBiographyId(state, getters) {
            return getters.profileData.biography_id
        },
        userBiography(state) {
            return state.userBiography
        },
        getBiographies(state) {
            return state.biographies
        },
        userProfileImage(state) {
            if (state.activeChildId !== null) return defaultImage
            if (state.activeProfile !== null && state.activeProfile !== state.userData.biography_id) {
                let element = state.userData.children.find(el => el.biography_id === state.activeProfile)
                return element && element.profile_image !== null ? element.profile_image : defaultImage
            } else return state.userData.profile_image !== null ? state.userData.profile_image : defaultImage
        },
        userNotification(state) {
            return state.userNotifications
        }
    }
};
