<template lang="pug">
.add-profile
    h2 ᲞᲠᲝᲤᲘᲚᲘᲡ ᲓᲐᲛᲐᲢᲔᲑᲐ
    DefaultInput.add-profile-content-input(text="სახელი" v-model:value="profileData.first_name")
    DefaultInput.add-profile-content-input(text="გვარი" v-model:value="profileData.last_name")
    button(@click="addProfile") ᲓᲐᲛᲐᲢᲔᲑᲐ

</template>

<script>
import DefaultInput from "@/components/layout/DefaultInput";
import {ajax, apiUrls} from "@/api/urls";
export default {
    name: "AddProfile",
    data () {
        return {
            profileData: {
                first_name: "",
                last_name: ""
            }
        }
    },
    components: {
        DefaultInput
    },
    methods: {
        addProfile() {
            ajax.post(apiUrls.addProfile, this.profileData).then(() => {
                this.$store.commit("modal/setModalComponent", { type: "" })
                this.$store.dispatch('user/userData')
            }).catch(() => {})
        }
    }
}
</script>

<style lang="scss" scoped>
.add-profile {
    width: 380px;
    height: 312px;
    display: flex;
    align-items: center;
    //justify-content: center;
    flex-direction: column;
    gap: 15px;

    h2 {
        font: normal normal bold 16px/19px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #212121;
        margin: 25px 0;
        text-align: center;
    }

    .add-profile-content-input {
        width: 264px;
    }

    button {
        width: 264px;
        height: 40px;
        border: none;
        background: #83E89E 0 0 no-repeat padding-box;
        box-shadow: 0 3px 20px #0000000D;
        border-radius: 10px;
        font: normal normal bold 14px/17px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #FFFFFF;
    }
}
</style>
