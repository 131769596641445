<template lang="pug">
header(:class="{'border-bottom': modalComponent.fullscreen === false && true}")
    router-link(to="/") Castingfor
    .right-side
        .links
            router-link(:to="{name: homePageName}") Home
            router-link(:to="{name: 'faq'}") FAQ
            router-link(:to="{name: 'safety'}") Safety
            router-link(:to="{name: 'termsConditions'}") Terms & Conditions
            router-link(:to="{name: 'contact'}") Contact
            span.not-login(v-if="!isAuthorized")
                router-link(:to="{name: 'Login'}") შესვლა
                router-link(:to="{name: 'Registration'}") რეგისტრაცია
        .notifications-container(v-if="isAuthorized" ref="notificationRef")
            .notification-button(@click="showNotifications=!showNotifications")
                img(src="@/assets/images/notification-icon.svg")
                .red-circle
            .notification-wrapper(:class="{'open': showNotifications, 'close': showNotifications !== null && !showNotifications}")
                .notification-item(v-if="userNotification && userNotification.length"
                    v-for="(item, index) in userNotification" :key="index"
                    :class="{'new-notification': item.status === 1}" )
                    h1(v-if="item.title" ) {{ item.title }}
                    p {{ item.description }}
                p(v-else) თქვენ არ გაქვთ შეტყობინებები
        UserDropdown(v-if="isAuthorized" )

</template>

<script>
import {mapGetters} from "vuex";
import UserDropdown from "@/components/UserDropdown";

// composition Api
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default {
    name: "Header",
    components: {
        UserDropdown
    },
    setup() {
        const notificationRef = ref(null)
        const showNotifications = ref(null)

        onClickOutside(notificationRef, () => { if (showNotifications.value === true) showNotifications.value = false })

        return { notificationRef, showNotifications }
    },
    computed: {
        ...mapGetters({
            isAuthorized: "user/isAuthorized",
            modalComponent: "modal/getModalComponent",
            userNotification: "user/userNotification"
        }),
        homePageName () {
            return this.isAuthorized ? 'Home' : 'Welcome'
        }
    }
}
</script>

<style lang="scss" scoped>

header.border-bottom {
    border-bottom: 1px solid #DED7D7;
}

header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0 30px;
    height: 78px;

    @media(min-width: 1920px) {
        max-width: 1920px;
        margin: 0 auto;
        padding: 20px 30px;
    }

    > a {
        font-size: 26px;
        font-weight: bold;
        color: #212121;
        font-family: sans-serif;
        cursor: pointer;
        user-select: none;
        text-decoration: none;
    }

    .right-side {
        display: flex;
        align-items: center;
        gap: 20px;

        .links {
            display: flex;
            align-items: center;

            a {
                display: inline-block;
                padding: 0 10px;
                font-size: 16px;
                text-decoration: none;
            }

            & > a {
                color: #6E6E6E;
            }

            .not-login {
                a {
                    font-weight: bold;
                    color: #212121;
                }
            }

            b {
                cursor: pointer;
            }
        }

        .notifications-container {
            position: relative;

            .notification-button {

                > img {
                    cursor: pointer;
                    user-select: none;
                }

                .red-circle {
                    position: absolute;
                    top: 0;
                    right: -1px;
                    width: 6px;
                    height: 6px;
                    background-color: #F86363;
                    border-radius: 50%;
                    cursor: pointer;
                    user-select: none;
                }
            }

            .notification-wrapper {
                position: absolute;
                top: 100%;
                right: -190px;
                margin-top: 36px;
                background-color: #FFFFFF;
                width: 350px;
                height: 630px;
                box-shadow: 0 3px 20px #00000029;
                border-radius: 10px;
                overflow: hidden;
                overflow-y: auto;
                opacity: 0;
                pointer-events: none;

                &.open {
                    animation: .4s cubic-bezier(.58, .3, .005, 1) SlideUp;
                    z-index: 10000;
                    transform: translateY(0);
                    opacity: 1;
                    pointer-events: auto;
                }

                &.close {
                    animation: .4s cubic-bezier(.58, .3, .005, 1) SlideDown;
                    transform: translateY(3.25rem);
                    pointer-events: none;
                    opacity: 0;
                }

                > p {
                    padding: 10px;
                }

                .notification-item {
                    padding: 20px 20px 0;

                    h1 {
                        font: normal normal bold 16px/19px 'Helvetica-Bold';
                        color: #6E6E6E;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 16px;
                        color: #6E6E6E;
                        padding-bottom: 20px;
                        border-bottom: 1px solid #DDDDDD;
                    }
                }

                .notification-item.new-notification {
                    background-color: #ebedf7;
                }
            }

            .notification-wrapper::-webkit-scrollbar {
                width: 4px;
            }

            .notification-wrapper::-webkit-scrollbar-track {
                background-color: #FFFFFF;
            }

            .notification-wrapper::-webkit-scrollbar-thumb {
                background-color: #E0DDDD;
                border-radius: 8px;
            }
        }
    }
}
</style>
