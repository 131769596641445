<template lang="pug">
.default-input
    span {{  text }}
    .input-wrapper(:class="{'error': errors && errors.length || errors}")
        input(
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :value="value"
            @input="changeValue"
            spellcheck="false"
            :style="styles"
            @keyup.enter="$emit('enterClick')")
        img(src='@/assets/images/error-outline.svg')
    p(v-for="err in errors") {{ err }}

</template>

<script>

export default {
    name: "DefaultInput",
    emits: ['update:value', 'enterClick'],
    props: {
        type: {
            type: String,
            default: 'text'
        },
        text: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: ''
        },
        errors: {
            type: null,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        styles: {
            type: String
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data () {
        return {}
    },
    methods: {
        changeValue(e) {
            this.$emit('update:value', e.target.value)
        }
    }
}
</script>

<style lang="scss">

.default-input {
    width: 100%;

    & > span {
        display: block;
        color: #434242;
        font-size: 12px;
        margin-bottom: 6px;
        padding-left: 4px;
    }

    .input-wrapper  {
        position: relative;
        width: 100%;

        input {
            width: 100%;
            padding: 0 10px;
            height: 30px;
            border: none;
            border-radius: 5px;
            box-shadow: 0 3px 20px #0000000D;
            font-size: 12px;

            &:focus {
                outline: none;
            }
        }

        img {
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translateY(-50%);
            display: none;
        }

        &.error {
            input {
                border: 1px solid #F21D1D;
            }
            img {
                display: block;
            }
        }
    }

    & > p {
        font-size: 10px;
        margin: 4px 0 0 5px;
        color: #FF3B58;
    }

}
</style>
