import {createRouter, createWebHistory} from 'vue-router'
import TokenService from '../services/token.service'
import tokenService from "../services/token.service"

import Website from "../views/Website";

const statusGuard = (to, from, next) => {
    const userStatus = TokenService.getUserStatus()
    if (userStatus === 0) {
        next({name: 'user-verification'})
    } else if (userStatus === 1) {
        document.title = 'Home'
        next({name: 'Home'})
    } else {
        next()
    }
}

const profileGuard = (to, from, next) => {
    if (tokenService.getItem('isChild') === 'true') {
        document.title = 'Home'
        next({name: 'Home'})
    } else {
        next()
    }
}

const routes = [
    {
        path: '/',
        component: Website,
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import(/* webpackChunkName: "Home" */ '../views/Home'),
                meta: {
                    protected: true,
                    title: 'Home'
                }
            },
            {
                path: 'post-detail/:postId',
                name: 'post-detail',
                component: () => import(/* webpackChunkName: "post-detail" */ '../views/PostDetail'),
                meta: {
                    protected: true,
                    title: 'Post Detail'
                }
            },
            {
                path: 'add-post',
                name: 'add-post',
                component: () => import(/* webpackChunkName: "add-post" */ '../views/AddPost'),
                meta: {
                    protected: true,
                    title: 'Add Post'
                },
                beforeEnter: [statusGuard, profileGuard],
            },
            {
                path: 'edit-post/:postId',
                name: 'edit-post',
                component: () => import(/* webpackChunkName: "edit-post" */ '../views/EditPost'),
                meta: {
                    protected: true,
                    title: 'Edit Post'
                },
                beforeEnter: [statusGuard, profileGuard],
            },
            {
                path: 'edit-profile',
                name: 'edit-profile',
                component: () => import(/* webpackChunkName: "edit-profile" */ '../views/EditProfile'),
                meta: {
                    protected: true,
                    title: 'Edit Profile'
                }
            },
            {
                path: 'my-posts',
                name: 'my-posts',
                component: () => import(/* webpackChunkName: "my-posts" */ '../views/MyPosts'),
                meta: {
                    protected: true,
                    title: 'My Posts'
                },
                beforeEnter: [statusGuard],
            },
            {
                path: 'welcome',
                name: 'Welcome',
                component: () => import(/* webpackChunkName: "Welcome" */ '../views/Welcome'),
                meta: {title: 'Welcome'}
            },
            {
                path: 'faq',
                name: 'faq',
                component: () => import(/* webpackChunkName: "faq" */ '../views/Faq'),
                meta: {title: 'Faq'}
            },
            {
                path: 'safety',
                name: 'safety',
                component: () => import(/* webpackChunkName: "safety" */ '../views/Safety'),
                meta: {title: 'Safety'}
            },
            {
                path: 'terms-conditions',
                name: 'termsConditions',
                component: () => import(/* webpackChunkName: "termsConditions" */ '../views/TermsConditions'),
                meta: {title: 'Terms and Conditions'}
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import(/* webpackChunkName: "contact" */ '../views/Contact'),
                meta: {title: 'Contact'}
            },
            {
                path: 'registration',
                name: 'Registration',
                component: () => import(/* webpackChunkName: "Registration" */ '../views/Registration'),
                meta: {
                    headerHide: true,
                    title: 'Registration'
                }
            },
            {
                path: 'verification/:uid/:token',
                name: 'Verification',
                component: () => import(/* webpackChunkName: "Verification" */ '../views/Verification'),
                meta: {
                    headerHide: true,
                    title: 'Verification'
                }
            },
            {
                path: 'user-verification',
                name: 'user-verification',
                component: () => import(/* webpackChunkName: "user-verification" */ '../views/UserVerification'),
                meta: {
                    headerHide: true,
                    protected: true,
                    title: 'User Verification'
                },
                beforeEnter: (to, from, next) => {
                    if (TokenService.getUserStatus()) {
                        document.title = from.meta.title
                        next({name: 'Home'})
                    } else {
                        next()
                    }
                }
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import(/* webpackChunkName: "Login" */ '../views/Login'),
                meta: {
                    headerHide: true,
                    title: 'Login'
                }
            },
            {
                path: 'recover-password',
                name: 'RecoverPassword',
                component: () => import(/* webpackChunkName: "RecoverPassword" */ '../views/RecoverPassword'),
                meta: {
                    headerHide: true,
                    title: 'Recover Password'
                }
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'pageNotFound',
                component: () => import(/* webpackChunkName: "pageNotFound" */ '../views/NotFound'),
                meta: {title: '404'}
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const protectedUrl = to.meta && to.meta.protected ? to.meta.protected : false
    const userIsLoggedIn = TokenService.getUser() ? TokenService.getUser() : false
    const allow = protectedUrl && userIsLoggedIn

    // set page title or use default
    document.title = to.meta.title ? to.meta.title : 'Castings'

    // isAuthorize guard
    if (!protectedUrl) {
        next()
    } else if (allow) {
        next()
    } else {
        next({name: "Welcome"})
    }
})

export default router
