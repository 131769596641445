import {ajax, apiUrls} from "../api/urls";
import TokenService from "./token.service";

const setup = (store) => {
    ajax.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            const CSRFToken = TokenService.getXCSRFToken();
            const activeProfile = TokenService.getItem('activeProfile')
            if (token) {
                config.headers["Authorization"] = 'JWT ' + token;
                if (activeProfile !== null) config.headers["ActiveProfileId"] = activeProfile
            }
            if (CSRFToken) {
                config.headers["X-CSRFToken"] = CSRFToken;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    ajax.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== apiUrls.tokenAuth && originalConfig.url !== apiUrls.tokensRefresh && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true
                    try {
                        if (TokenService.getLocalRefreshToken()) {
                            await ajax.post(apiUrls.tokensRefresh, {
                                refresh: TokenService.getLocalRefreshToken()
                            }).then((res) => {
                                TokenService.setUser(res.data);
                            }).catch((err) => {
                                if (err.response.status === 401) {
                                    store.commit('user/logout')
                                    TokenService.removeUser()
                                }
                            })

                            return ajax(originalConfig);
                        }
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};

export default setup;
