<template lang="pug">
.user-filter(@click="emitSelected")
    slot(name="deleteIcon" )
    .tag(v-if="showTag" :class="[data.is_active ? 'active' : '']")
        span {{ data.is_active ? "ᲐᲥᲢᲘᲣᲠᲘ" : "ᲐᲠᲐ ᲐᲥᲢᲘᲣᲠᲘ" }}
    .people-cont
        img(src="@/assets/images/Icon-material-people.svg")
        p {{ data.number_of_model_needed }}
    .user-filter-main-cont
        .gender
            img(src="@/assets/images/icon-monstr-gender-5.svg")
            img(v-if="data.gender === 0" src="@/assets/images/Icon-awesome-male.svg")
            img(v-else src="@/assets/images/Icon-awesome-female.svg")
        .age
            h4 ას
            p {{ age }}
        .skin
            img(src="@/assets/images/Icon-ionic-md-hand.svg")
            .skin-color()
        .height
            .height-icon
                img(src="@/assets/images/Icon-awesome-ruler.svg")
                img(src="@/assets/images/Icon-awesome-male.svg")
            p {{ height }}
        .filter-item
            h4 თფ
            .filter-item-circle
        .weight
            img(src="@/assets/images/Icon-awesome-weight.svg")
            p {{ weight }}
        .eye-color
            img(src="@/assets/images/Icon-awesome-eye.svg")
            .eye-color-circle(:class="{brown: data.eye_color === 2}")
        .additional-item
            img(src="@/assets/images/Icon-feather-plus.svg")
            p {{ data.other_characteristics[0]?.title }}
    button(v-if="buttonText" @click="makeOffer") {{ buttonText }}

</template>

<script>
import {mapGetters} from "vuex";
import {ajax, apiUrls} from "@/api/urls";
import {useToast} from "vue-toastification";

export default {
    name: "UserFilter",
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        buttonText: {
            type: String,
            default: ''
        },
        showTag: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const toast = useToast()
        return {toast}
    },
    computed: {
        ...mapGetters({
            userBiographyId: 'user/userBiographyId',
            profileData: 'user/profileData'
        }),
        age() {
            return this.data.min_age + "წ" + "-" + this.data.max_age + "წ"
        },
        height() {
            return this.data.min_height + "სმ" + "-" + this.data.max_height + "სმ"
        },
        weight() {
            return this.data.min_weight + "კგ" + "-" + this.data.max_weight + "კგ"
        }
    },
    methods: {
        emitSelected() {
            if (this.showTag) {
                this.$emit('setSelected', this.data.id)
            }
        },
        makeOffer() {
            if (this.profileData.status === 0 || this.profileData.status === undefined) {
                if (this.userBiographyId) {
                    ajax.post(apiUrls.offers, {vacancy: this.data.id, biography: this.userBiographyId}).then(() => {
                        this.$store.dispatch('user/fetchActiveOffers')
                        this.toast.success('შეთავაზება წარმატებით გაიგზავნა')
                    }).catch(error => {
                        if (error.response.status === 400) {
                            this.toast.error('თქვენ უკვე გაგზავნილი გაქვთ შეთავაზება')
                        }
                    })
                } else {
                    this.toast.info('შეიყვანეთ ბიომეტრიული პარამეტრები')
                    this.$router.push({name: 'edit-profile'})
                }
            } else {
                this.toast.error('ვაკანსიაზე შეთავაზების გაგზავნის უფლება მხოლოდ მსახიობს აქვს')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.user-filter {
    position: relative;

    .tag {
        width: max-content;
        padding: 1px 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 5px;
        background-color: #F5D98D;

        span {
            font: normal normal bold 11px/13px "Helvetica-Regular";
            letter-spacing: 0;
            color: #FFFFFF;
        }
    }

    .tag.active {
        background-color: #92EB8E;
    }

    :deep(.delete-icon) {
        position: absolute;
        top: -5px;
        right: 0;
        cursor: pointer;
        width: 15px;
        height: 15px;
    }

    .people-cont {
        display: flex;
        align-items: center;
        gap: 15px;

        p {
            font-size: 14px;
            color: #6E6E6E;
        }
    }

    .user-filter-main-cont {
        display: grid;
        align-items: center;
        grid-auto-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 15px;
        gap: 15px;

        .gender, .age, .skin, .height, .filter-item, .weight, .eye-color, .additional-item {
            display: flex;
            align-items: center;
            gap: 15px;
            flex: 1 0 21%;
        }

        .age, .filter-item {

            h4 {
                font: normal normal bold 14px/15px 'Helvetica-Bold';
                color: #212121;
            }

            p {
                font-size: 14px;
                color: #6E6E6E;
            }
        }

        .skin, .filter-item {

            .skin-color, .filter-item-circle {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: #F0D895;
            }

            .skin-color.black {
                background-color: #000000;
            }
        }

        .height, .weight, .additional-item {

            .height-icon {
                display: flex;
                align-items: center;
            }

            p {
                font-size: 14px;
                color: #6E6E6E;
            }
        }

        .eye-color {
            .eye-color-circle {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: #5751F4;
            }
        }
    }

    > p {
        margin: 25px 0 0;
        font-size: 14px;
        text-align: center;
        color: #F86363;
        font-weight: 600;
    }

    > button {
        display: block;
        border: none;
        background: #4354B4 0 0 no-repeat padding-box;
        border-radius: 8px;
        font: normal normal bold 12px/15px 'Helvetica-Bold';
        letter-spacing: 0;
        color: #FFFFFF;
        padding: 3px 8px;
        margin: 20px 0 0 auto;
    }
}
</style>
