<template lang="pug">
teleport(to="body")
    .default-modal(@click="closeModal" :class="{fullscreen: modalComponent.fullscreen}")
        .default-content(v-if="modalComponent.withContainer" @click.stop :class="{'screen-center': modalComponent.fullscreen === false && true}")
            .close-modal(@click="closeModal")
                img(src="@/assets/images/close.png")
            slot
        .modal-content(v-else @click.stop :class="{'post-content': modalComponent.type === 'Post', 'screen-center': modalComponent.fullscreen === false && true}")
            slot
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "DefaultModal",
    mounted() {
        document.body.style.overflow = 'hidden';
    },
    unmounted() {
        document.body.style.overflow = 'visible';
    },
    computed: {
        ...mapGetters({
            modalComponent: "modal/getModalComponent"
        })
    },
    methods: {
        closeModal () {
            this.$store.commit("modal/setModalComponent", {})
        }
    }
}
</script>

<style lang="scss" scoped>

.default-modal.fullscreen {
    height: 100vh;
    top: 0;
}

.default-modal {
    position: fixed;
    top: 79px;
    left: 0;
    height: calc(100vh - 78px);
    width: 100%;
    background-color: rgba(255, 255, 255, 0.88);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .default-content {
        position: relative;
        background-color: #FFFFFF;
        padding: 20px;
        opacity: 1 !important;
        box-shadow: 0 3px 20px #00000029;
        border-radius: 5px;

        .close-modal {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;

            > img {
                width: 14px;
                height: 14px;
            }
        }
    }

    .modal-content.post-content {
        max-width: 650px;
    }

    .modal-content.screen-center, .default-content.screen-center {
        margin-top: -79px;
    }
}
</style>
