export default function (param) {
    try {
        const date = new Date(param);
        let day = date.getDay();
        let month = date.getMonth();

        switch (day) {
            case 0: day = "ორშაბათი"; break;
            case 1: day = "სამშაბათი"; break;
            case 2: day = "ოთხშაბათი"; break;
            case 3: day = "ხუთშაბათი"; break;
            case 4: day = "პარასკევი"; break;
            case 5: day = "შაბათი"; break;
            case 6: day = "კვირა"; break;
        }

        switch (month) {
            case 0: month = "იანვარი"; break;
            case 1: month = "თებერვალი"; break;
            case 2: month = "მარტი"; break;
            case 3: month = "აპრილი"; break;
            case 4: month = "მაისი"; break;
            case 5: month = "ივნისი"; break;
            case 6: month = "ივლისი"; break;
            case 7: month = "აგვისტო"; break;
            case 8: month = "სექტემბერი"; break;
            case 9: month = "ოქტომბერი"; break;
            case 10: month = "ნოემბერი"; break;
            case 11: month = "დეკემბერი"; break;
        }

        return {
            day: day, dayNumber: date.getDate(),
            month: month, year: date.getFullYear()
        };
    }
    catch (err) {
        return {day: '', dayNumber: '', month: '', year: '', err: true};
    }
}
